import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { BlockContent } from '../components/BlockContent'
import { Layout } from '../components/Layout/Layout'
import { SEO } from '../helpers/SEO'
import { BlogMeta } from '../components/blog/BlogMeta/BlogMeta'
import { Author } from '../components/blog/blogTypes'
import { imageUrlFor, buildImageObj } from '../helpers/imageUrl.js'
import { HeroImage } from '../components/HeroImage'
import { device } from '../components/GlobalStyles/helpers/device'

const PageWrapper = styled.div``

const PostContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media ${device.tablet} {
    flex-direction: row-reverse;
  }
`

const PostBody = styled(BlockContent)`
  @media ${device.tablet} {
    width: 70%;

    ul {
      list-style: disc;
      padding-left: 70px;
    }
  }
`

interface Props {
  data: {
    post: {
      id: string
      author: Author
      publishedAt: string
      categories: Array<{
        _id: string
        title: string
      }>
      mainImage: {
        alt: string
        asset: {
          fluid: {
            src: string
          }
        }
      }
      title: string
      slug: {
        current: string
      }
      _rawBody: React.ReactNode
    }
  }
}

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      author {
        image {
          alt
          ...SanityImage
        }

        _rawBio
        name
        slug {
          current
        }
      }
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        alt
        ...SanityImage
      }
      title
      slug {
        current
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`

const blogPost = ({ data }: Props): JSX.Element => {
  const { post } = data
  const mainImage =
    post.mainImage &&
    imageUrlFor(buildImageObj(post.mainImage))
      .width(1200)
      .height(Math.floor((9 / 16) * 1200))
      .fit('crop')
      .auto('format')
      .url()

  return (
    <Layout>
      {post && <SEO title={post.title} image={mainImage} />}
      <PageWrapper>
        {post.mainImage && <HeroImage src={mainImage} alt={post.mainImage.alt} />}
        <h1>{post.title}</h1>
        <PostContainer>
          <BlogMeta author={post.author} categories={post.categories} />
          {post._rawBody && <PostBody body={post._rawBody} />}
        </PostContainer>
      </PageWrapper>
    </Layout>
  )
}

export default blogPost
