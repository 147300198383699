import React from 'react'
import styled from 'styled-components'
import { Listing } from '../../Listing'
import { Author } from '../blogTypes'
import { imageUrlFor, buildImageObj } from '../../../helpers/imageUrl.js'
import { device } from '../../GlobalStyles/helpers/device'

const Container = styled.div`
  @media ${device.tablet} {
    width: 30%;
  }
`

interface Props {
  author: Author
  categories: Array<{
    _id: string
    title: string
  }>
}

const Category = styled.li`
  text-align: center;
  margin-top: 5px;
`

export const BlogMeta = ({ author, categories }: Props): JSX.Element => {
  return (
    <Container>
      {author && (
        <Listing
          title={author.name || 'No Author'}
          //link={author.slug.current}
          img={imageUrlFor(buildImageObj(author.image)).width(100).height(100).auto('format').url() || ''}
          desc={author._rawBio}
          alt={author.image.alt}
        />
      )}
      <ul>
        {categories?.map((category) => (
          <Category key={category._id}>{category.title}</Category>
        ))}
      </ul>
    </Container>
  )
}
